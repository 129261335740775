import React from "react";

import { Accordion } from 'flowbite-react';
import { Card } from 'flowbite-react';
import imageHistory from "../assets/pepe-history.jpg"


const About = () => {




  return (
    <div
      name="About"
      className=" flex flex-col items-center   "
      style={{ paddingTop: "20px" }}
    >
      <h2 className="titulo">About</h2>
      <div class="box">
        <span >Titulo</span>
        <p>
          
          Lorem, ipsum dolor sit amet consectetur adipisicing elit. Repellat tempora provident atque eum laboriosam, architecto consectetur rerum accusamus hic, dignissimos qui molestias, aut fugit. Nisi quam dolor temporibus optio. Culpa?
          Lorem ipsum dolor sit amet, consectetur adipisicing elit. Reiciendis praesentium nisi, vel nam veniam ullam sapiente dolores beatae impedit velit, adipisci repellendus explicabo ipsum quis, doloribus vero. Natus, animi aperiam.
        </p>
      </div>
    </div>
  );
};

export default About;
