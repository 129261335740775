import React from "react";
import { useState, useEffect } from 'react'


import { Button } from 'flowbite-react';
import useSound from "use-sound";
import ranaSound from "../assets/rana.mp3"

import pepe1 from "../assets/geckoBTCbanner.jpg";
import pepe2 from "../assets/geckoProfit.jpg";
import pepe3 from "../assets/geckoBTCbanner.jpg";


const Home = () => {


  const [useRanaSound] = useSound(ranaSound)
  return (
    <div style={{ marginLeft: "5%", marginRight: "5%" }}>
      <div className="flex flex-col items-center">
        <div className="slides">
          <div className="slide slide_1">
            <img

              src={pepe1}
              alt=""
              class="slide_image"
            />
          </div>
          <div class="slide slide_2">
            <img
              src={pepe2}
              alt=""
              class="slide_image"
            />
          </div>
          <div class="slide slide_3">
            <img
              src={pepe3}
              alt=""
              class="slide_image"
            />
          </div>
        </div>
      </div>
      <div className="flex flex-col items-center">

        <button onClick={useRanaSound} className="boton">BUY</button>
        

      </div>


    </div>

  );
};

export default Home;
