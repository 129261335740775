import Home from "./components/Home";
import NavBar from "./components/NavBar";
import About from "./components/About";
import Tokenomics from "./components/Tokenomics";

import SlideBar from "./components/SlideBar";

import punteroPepe from "./assets/pepe1png.png"
import { useState, useEffect } from 'react'
import TaylorPNG from "./assets/geckopng.png"

function App() {
  
  return (
    <div>
       

    
    <div >
      <img className="taylorPNG" src={TaylorPNG}></img>
      <NavBar />
      
      <Home />
       <div className="grid h-56 grid-cols-2 gap-4 sm:h-64 xl:h-80 2xl:h-96 sm:grid-cols-1">
       <About />
      <Tokenomics />
        
       </div>
      

    </div>
    </div>
  );
}

export default App;
