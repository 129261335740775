'use client';


import { Navbar ,Button} from 'flowbite-react';
import { Link } from "react-scroll";
import "./estilos.css"
import telegram from "../assets/telegram.png"
import twitter from "../assets/twitter.png"
import geckoFace from "../assets/geckofacePNG.png"


const NavBar = () => {
  

  return (
    <div>
      <div className="flex ">
      <button  ><img src={telegram} alt="" style={{width:"30px",height:"30px",margin:"25px"}} /></button>
      <button  ><img src={twitter} alt="" style={{width:"30px",height:"30px",margin:"25px"}} /></button>
      <img src={geckoFace} style={{width:"30px",height:"30px",margin:"25px"}}></img>
        </div> 
      <ul >
        <li>Home</li>
        <li><Link to="About" smooth={200}>About</Link></li>
        <li><Link to="Tokenomics" smooth={200}>Tokenomics</Link></li>
        
      </ul>
      
      
  

  </div> 
  );
};

export default NavBar;
