import React, { useEffect, useState } from "react";
import projectImage from "../assets/pepe-coin.jpg";
import frontIMG from "../assets/geckoBTCbanner.jpg"
import backIMG from "../assets/geckoBTC.png"


const Tokenomics = () => {


  return (
    <div name="Tokenomics" className="flex flex-col items-center "  >

      <div class="cardToken">
        <div class="front"><img src={backIMG}></img></div>
        <div class="back">
           <p style={{margin:"15px"}}>Total Supply: 1B tokens</p>
           <p style={{margin:"15px"}}>Liquidity Burned</p>
           <p style={{margin:"15px"}}>Taxes NoTax</p>
          </div>
      </div>

    </div>
  );
};

export default Tokenomics;
